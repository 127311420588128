import { useEffect, useState } from "react";
import * as firebase from "./firebase.js";
import * as auth from "./auth.js";
import * as db from "./db.js";
import * as startup from "./startup.js";
import * as localstorage from "./localstorage.js";
import { SideBar } from "../dashboard/sidebar/sidebar.js";
import { SideBarItem } from "../dashboard/sidebar/SideBarItem.js";
import { useNavigate } from "react-router-dom";


export function StartServices() {
   const [sidebarTitle, setSidebarTitle] = useState("User...");
   const [isReady,setReady] = useState(false);
   const [theme,toggleTheme]=useState(false);
   let navigate = useNavigate();
   document.title = "Turtle Tank | Dashboard"
   async function init() {
      console.log("Starting Services...");
      firebase.init();
      localstorage.init()
      const result = await auth.init(); 
      if(!result)window.location.pathname = "/login"
      db.init();
      await startup.init();
      setSidebarTitle(startup.currentStartup.name);
      setReady(true);
      if(Object.keys(localstorage.localData).includes("theme")){
         toggleTheme(localstorage.localData["theme"])
      }
   }

   useEffect(() => { init() }, []);

   useEffect(()=>{
      if(!isReady)return;
      theme?document.body.classList.add("dark"):document.body.classList.remove("dark");
      localstorage.setData('theme',theme);
   },[theme])
   function handleTheme(){
      toggleTheme(!theme)
   }

   if(!isReady)return <>Loading</>
   return (
      (
         <SideBar title={sidebarTitle} onThemeChange={handleTheme}>
            <SideBarItem link="/dashboard">Dashboard</SideBarItem>
            <SideBarItem link="/dashboard/assessment">Assessment</SideBarItem>
            <SideBarItem link="/dashboard/profile">Profile</SideBarItem>
            <SideBarItem link="/dashboard/resources">Resources</SideBarItem>
         </SideBar>
      )
   )
}