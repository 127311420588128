import { useNavigate } from "react-router-dom";
import { startAssessment } from "../services/assessment";
import "../styles/assessment.css";
const fields = ["score", "cards", "stage", "challengerCards"];
export var currentAssessment = null;

export function Assessment() {
   const navigate = useNavigate();
   function handleClick() {
      //(localStorage);
      if (localStorage.length > 0) {
         currentAssessment = {
            stage: localStorage.getItem(fields[2]),
            data: {
               stage1: {
                  cards: JSON.parse(localStorage.getItem(fields[1]))
               },
               stage2: {
                  challengerCards: JSON.parse(localStorage.getItem(fields[3]))
               }
            },
            score: localStorage.getItem(fields[0])
         };

         if(currentAssessment.stage == 3){
            //console.log(currentAssessment);
            navigate("/dashboard/assessment/action-plan");
            //console.log("Finished part 2");
         } else if (currentAssessment.stage == 2){
            //console.log(currentAssessment);
            navigate("/dashboard/assessment/challenger");
            //console.log("Finished part 1");
         }
      
      } else{
         startAssessment();
         navigate("/dashboard/assessment/turtle")
      }
   }

   return (
      <div className="assessment">
         <h1 style={{ color: "var(--theme-accent-2)" }} id="assessment-header">Assessment</h1>
         <p style={{ color: "var(--theme-accent-4)" }} id="assessment-instruction-header"><b>Assessment instructions:</b></p>
         <ol id="assessment-instructions">
            <li>You will be presented with a series of cards</li>
            <br />
            <li>Read the card and click on the button that best describes your current situation</li>
            <br />
            <li>After that you reach the challenger cards section</li>
            <br />
            <li>Drag and drop cards to either reject it, keep it as todo, or if you already have implemented it</li>
            <br />
            <li>After that you will be presented with an action plan</li>
            <br />
            <li>Fill up intro and navigate to each todo section and fill in those as well</li>
         </ol>

         <button className="turtle-button" onClick={handleClick} id="start-button">start</button>
      </div>
   );
}