import { useEffect, useRef, useState } from "react";
import { currentAssessment, finishChallenger, getChallengerCards, getChallengerSection } from "../../services/assessment";
import "../../styles/challenger.css"
import { DropBar } from "./DropBar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useNavigate } from "react-router-dom";
import { challengerSections } from "../../services/Data";

export function Challenger() {
   const navigate = useNavigate()
   const [currSection, setSection] = useState(0);
   const challengerCardsTotal = useRef([]);

   function handleNext(accepts, todos) {
      var challengerCards = []
      accepts.forEach((x) => {
         challengerCards.push({ id: x.id, status: 0 });
      })
      todos.forEach((x) => {
         challengerCards.push({ id: x.id, status: 1 });
      })

      challengerCardsTotal.current = challengerCardsTotal.current.concat(challengerCards);
      if (currSection == challengerSections.length - 1) {
         finishChallenger(challengerCardsTotal.current);
         navigate("/dashboard/assessment/action-plan");
      } else {
         setSection(x => x + 1);
      }
   }

   return <>
      <h3 id="title">{challengerSections[currSection].title}</h3>
      <h4 id="directions">{challengerSections[currSection].directions}
         <br/>
         <div className="directions-style">
         <span style={{
            color:"var(--theme-text-1)",
            fontSize:"17px",
            textTransform: "initial"

         }}>Challenger Cards - sort the cards provided into the categories based on which category fits each guideline best. These will be used to draft an "action plan" at the end of the assessment.</span>
      </div>
      </h4>
         
      <ChallengerSection key={currSection} sectionId={currSection} onNext={handleNext} />
   </>
}

export function ChallengerSection({ sectionId, onNext }) {
   const cards = getChallengerSection(sectionId).cards;
   const [accepts, setAccepts] = useState([]);
   const [rejects, setRejects] = useState([]);
   const [todos, setTodos] = useState(cards);

   const [currentCardCoords, setCurrCardCoords] = useState(null);

   useEffect(() => {
      if (currentCardCoords) {
         let [bar, cardIndex] = currentCardCoords;
         let cardEls = document.getElementsByClassName("dropbar")[bar].getElementsByClassName("dragcard");
         let cardEl = cardEls[cardIndex];
         let lastCard = cardEls.item(cardEls.length - 1)
         if (cardEl && (cardEl == lastCard)) cardEl.scrollIntoView({ behavior: "smooth" });

         setCurrCardCoords(null);
      }
   }, [accepts, todos, rejects])



   var handleCard = (id, cardId, newCardIndex) => {

      //ik its a bit messy but it works (woah that comment was ai generated lol)
      let card = cards.find(c => c.id === cardId);
      var c = (x, _i) => {
         let x_index = x.findIndex((y) => y.id == card.id);
         const _x = x.slice();

         if (x_index != -1) _x.splice(x_index, 1);

         if (id == _i) {
            _x.splice(newCardIndex, 0, card);
            //scroll there

         }

         return _x;
      }

      setCurrCardCoords([id, newCardIndex]);
      setAccepts((x) => c(x, 0));
      setTodos((x) => c(x, 1));
      setRejects((x) => c(x, 2));
   }

   useEffect(() => {
      if (!currentAssessment) {
         console.log(currentAssessment)
         alert("Please start the assessment first");
         window.location.href = "/dashboard/assessment";
      } else if (currentAssessment.stage != 2) {
         alert("You have already completed this stage 2");
      }
   }, [])

   return (
      <DndProvider backend={HTML5Backend}>
         <div className="challenger">
            <DropBar cards={accepts} subtitle="Drag and Drop the cards here if have already implemented them" title="We already do this 🟩" onItem={(cid, nci) => handleCard(0, cid, nci)}></DropBar>
            <DropBar cards={todos} subtitle="Drag and Drop the cards here if you plan to implement them in future" title="We will do this 🟨" onItem={(cid, nci) => handleCard(1, cid, nci)}></DropBar>
            <DropBar cards={rejects} subtitle="Drag and Drop these cards to its respective boxes" title="We don't plan to do this 🟥" onItem={(cid, nci) => handleCard(2, cid, nci)}></DropBar>
         </div>
            <button id="n-btn" className="turtle-button" onClick={() => onNext(accepts, todos)}>Next Segment &rarr;</button>
      </DndProvider>

   );
}
