import React, { useEffect, useState } from "react";
import { db } from "../services/db";
import { collection, getDocs } from "firebase/firestore";

function filterout(Startup) {
  if (Startup.coFounderName) {
    let invalid_names = ["steven zhang", "steven doe", "dsa", "yuxiang", "asdasd"];
    let tracker = false;
    invalid_names.forEach((name) => {
      if (Startup.coFounderName.trim().toLowerCase() == name) tracker = true;
    })

    if (tracker) return false;
    else return true;

  }

  if (Startup.desc) {
    let invalid_descriptions = ["test startup", "test", "get sum money", "testing", "fvrvrvdvrvr", ")", "i like this startup.. me too"];
    let tracker = false;
    invalid_descriptions.forEach((descriptions) => {
      if (Startup.desc.trim().toLowerCase() == descriptions) tracker = true;
    })
    if (tracker) return false;
    else return true;
  }

  return false;
}

export function Admin() {
  const [startupData, setStartupData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "startups"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(data);
        setStartupData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="admin-container">
      <h2 className="title">Startup List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        startupData
          .filter((startup) =>
            filterout(startup)
          )
          .map((startup) => (
            <div key={startup.id} className="profile">
              <div className="about">

                <h2>{startup.name}</h2>
                <h3>{startup.coFounderName}</h3>
                <p className="description-container">{startup.desc}</p>
                <p>{startup.coFounderPhone}</p>
                <p>{startup.location}</p>
                <p>{startup.website}</p>

                <div className="">
                {startup.timeline.length === 0 ? (
                  <div className="score-cont-admin">
                    <span className="scores">No Assessments!</span>
                  </div>
                ) : (
                  <div className="score-cont-admin">
                    <span className="scores">
                      Latest Score: {startup.timeline.sort((a, b) => b.date.seconds - a.date.seconds)[0].score}
                    </span>

                    <span className="scores">
                      Highest Score: {startup.timeline.sort((a, b) => b.score - a.score)[0].score}
                    </span>
                  </div>
                )}
              </div>
              </div>

             
            </div>


          ))
      )}
    </div>
  );
}
