import { useEffect, useState } from "react";
import "../styles/dashboard.css"
import { getAssessments, getChallengerCards, getTurtleCards } from "../services/assessment";
import { useNavigate } from "react-router-dom";
import { challengerLength } from "../services/Data";

export function Dashboard() {
   const [timeline, setTimeline] = useState([]);
   const navigate = useNavigate()
   useEffect(() => {
      setTimeline(getAssessments());
   }, [])
   

   function getIntro(content) {
      let temp = content;
      if (temp.length > 100) {
         temp = temp.substring(0, 100) + "...";
      };
      return temp;
   }
   return (
      <div className="dashboard-content">
         <h1 style={{color:"var(--theme-accent-2)"}}>Dashboard</h1>
         <div>
            <h2>Timeline</h2>
            {
               timeline.length == 0 && <p>No Assessments yet</p>
            }
            <div className="timeline">
               {
                  timeline.sort((a,b)=>b.date.seconds-a.date.seconds).map((item, i) =>
                     <div onClick={() => navigate("/dashboard/assessment/view/" + i)} className="timeline-item" key={item.date}>
                        <h3 style={{textAlign:"center"}}>Score - {item.score}</h3>
                        <p>Date of Assessment - {item.date.toDate().toLocaleDateString()}</p>
                        <div>Number of Cards chosen- {item.turtle_cards.concat(item.challenger_cards).filter(x => x.status == 0).length}</div>
                        <div>Number of Cards set for Todo- {item.turtle_cards.concat(item.challenger_cards).filter(x => x.status == 1).length}</div>
                        <div>Number of Cards rejected- {item.turtle_cards.filter(x => x.status == 2).length + (challengerLength + 1 - item.challenger_cards.length)}</div>
                        <h4 style={{margin:"8px 0"}}>Introduction</h4>
                        {(item.action_plan>0) && <p>{getIntro(item.action_plan.find(x => x.id == "intro").content)}</p>}
                     </div>
                  )
               }
            </div>
         </div>
      </div>
   );
}