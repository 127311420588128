import { currentStartup } from "./startup";
import { challengerSections, challengerSets, turtleSets } from "./Data";
import { Timestamp, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "./db";

const fields = ["score", "cards", "stage", "challengerCards"];
export var currentAssessment = null;

export function startAssessment() {

   currentAssessment = {
      stage: 1,
      data: {},
   };

}

export function getTurtleCards() {
   return turtleSets;
}

export function getChallengerCards() {
   return challengerSets;
}

export function getChallengerSection(sectionId) {
   return challengerSections[sectionId];
}

export function endAssessment() {
   currentAssessment = null;
}

export function getAssessments() {
   return currentStartup.timeline;
}

export function getAssessment(id) {
   return currentStartup.timeline[id];
}

export function finishTurtle(score, cards) {
   //console.log("Finished turtle part");
   currentAssessment.data.stage1 = {
      score,
      cards,
   };
   currentAssessment.stage = 2;
   localStorage.setItem(fields[2], 2);
   localStorage.setItem(fields[0], score);
   localStorage.setItem(fields[1], JSON.stringify(cards));
}


export function finishChallenger(challengerCards) {
   currentAssessment.data.stage2 ?? (currentAssessment.data.stage2 = {});
   currentAssessment.data.stage2 = {
      challengerCards,
   };
   currentAssessment.stage = 3;
   localStorage.setItem(fields[2], 3);
   localStorage.setItem(fields[3], JSON.stringify(challengerCards));
}

export async function finishActionPlan(actionPlanData) {
   currentAssessment.data.stage3 = actionPlanData;

   let finalScore =
      currentAssessment.data.stage1.cards.reduce((total, curr) => total + curr.score, 0) +
      currentAssessment.data.stage2.challengerCards.reduce((total, curr) => total + (curr.status == 0 ? 10 : 0), 0);

   var docData = {
      date: Timestamp.now(),
      score: finalScore,
      turtle_cards: currentAssessment.data.stage1.cards.map((x) => {
         x.status = 2 - x.score / 5;
         delete x.score;
         return x;
      }),
      challenger_cards: currentAssessment.data.stage2.challengerCards,
      action_plan: Object.keys(currentAssessment.data.stage3).map((k) => ({
         id: k,
         content: currentAssessment.data.stage3[k],
      })),
   };
   await updateDoc(doc(db, "startups", currentStartup.owneruid), { timeline: arrayUnion(docData) });
   currentAssessment = null;
   localStorage.clear();
}


/*
{
   DATABSSE
   date,
   score,
   turtle_cards[] {status:0,1,2, id:0-16},
   challenger_cards[] {status:0,1,2, id:27-99}},
   action_plan [
            id: 'intro' || number
            content
         ],
}*/