export var localData = null

export function init(){
   localStorage.clear();
   localData = {};
   let index=0;
   let currentKey;
   while ((currentKey = localStorage.key(index))){
      localData[currentKey] = JSON.parse(localStorage.getItem(currentKey));
      index+=1;
   }
}

export function setData(key,value){
   localStorage.setItem(key,value);
   localData[key] = value;
}